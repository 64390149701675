import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { PromotionResponse, ShopPoints, CatalogueResponse } from '@model/promotion-response.model';
import { BaseUrlService } from './url.service';

@Injectable()
export class PromotionService extends BaseUrlService {
    constructor(public http: HttpClient, public router: Router) {
        super(router);
    }

    getPromotion() {
        this.checkBaseUrl();
        return this.http.get<any>(`${this.instanseUrl}/mobileapp/outlook/eligiblePromotions.action`).pipe(map((response: PromotionResponse) => {
            return response;
        }));
    }

    getRedirectUrl() {
        this.checkBaseUrl();
        return this.http.get(`${this.instanseUrl}/outlook/redirectUrl.action`).pipe(map((response: any) => {
            return response.url;
        }));
    }

    getRedirectUrlNew() {
        this.checkBaseUrl();
        return this.http.get(`${this.instanseUrl}/outlook/redirectUrl.action`).pipe(map((response: any) => {
            return response.url;
        }));
    }

    getShopPoints() {
        this.checkBaseUrl();
        return this.http.get(`${this.instanseUrl}/mobileapp/outlook/shopPoints.action`).pipe(map((response: ShopPoints) => {
            return response;
        }));
    }

    getCatalogueUrl(type: string) {
        this.checkBaseUrl();
        return this.http.get(`${this.instanseUrl}/outlook/shopRedirectUrl.action?shopType=${type}`).pipe(map((response: CatalogueResponse) => {
            return response;
        }));
    }
}