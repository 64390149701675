<loader *ngIf="loading"></loader>
<div class="otlkParticipantPopupHeader">
    <div class="otlkParticipantPopupHeaderInner dfr jcsb aic">
        <div>
            <button class="otlkBtn otlkBtn--tertiary cp" (click)="closeModalWindow()">
                {{'Participants.Cancel' | translate}}
            </button>
        </div>
        <div>
            <span class="fwb">{{'Participants.To' | translate}}</span>
        </div>        
    </div>
</div>

<div class="otlkParticipantPopupContent flexGrow">

    <div class="searchWrap">

        <!-- Add "searchWrapSearch--focus" class to below div when on focus to bring up the blue border color -->
        <div class="searchWrapSearch dfr" [ngClass]="{'searchWrapSearch--focus': isSearchFocus}">
            <!-- Below span should be hidden when the input box below the span is focussed -->


            <!-- Below spans are the user name tags. These should be added for each user -->
            <span class="otlkBadge otlkBadgeWithClose dib ml5" *ngFor="let participant of selectedParticipants">
                <span>{{participant.firstName}} {{participant.lastName}} </span>
                <span class="otlkBadgeClose" (click)="removeParticipant(participant)">
                    <fa-icon [icon]="faTimes"></fa-icon>
                </span>
            </span>

            <span class="searchWrapSearchIconHolder">
                <i class="gg-search searchWrapSearchIcon" *ngIf="!isSearchFocus"></i>
                <input type="text" #searchBox class="searchWrapSearchInput"
                    [placeholder]="selectedParticipants.length==0 ? ('Participants.SearchByLastName' | translate) : ''"
                    (focus)="focusInput()" (blur)="focusInput()" (keyup)="recipientSearch(searchBox.value)"
                    (keydown)="onKeyup($event)" tabindex="0" />
            </span>
        </div>

        <!-- Below div to be shown when user searches for any participant. Simply hardcoding ngIf to false for now -->
        <div class="searchWrapResultsHolder" style="z-index: 100;" *ngIf="openAutoComplete">
            <div class="searchWrapResults">
                <p class="searchWrapResultsTitle">
                    <span *ngIf="!showSearchError">{{'Participants.SuggestedPeople' | translate}}</span>
                    <span *ngIf="showSearchError">{{'Participants.RefineSearchCriteria' | translate}}</span>
                </p>
                <ul class="userListWrapList" id="userListWrapList" (keydown)="onKeydown($event)" tabindex="0">
                    <!-- To be repeated for each user -->
                    <list-item *ngFor="let participant of searchParticipants" class="userListWrapListItemComp"
                        [participant]="participant" (click)="selectParticipant(participant)"
                        (itemSelected)="selectParticipant($event)">
                    </list-item>
                </ul>
            </div>
        </div>
    </div>

    <div class="otlkAccordianWrap mt15">
        <div class="otlkAccordianWrapOverlay" *ngIf="openAutoComplete"></div>
        <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0,ngb-panel-1,ngb-panel-2">
            <ngb-panel id="ngb-panel-0" *ngIf="recentParticipants.length>0">
                <ng-template ngbPanelHeader let-opened="opened">
                    <i class="accordianHeaderIcon gg-chevron-right" [ngClass]="{'collapsed': !opened}"></i>
                    <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0">
                        <span>
                            {{'Participants.Recent' |translate}}
                        </span>
                    </button>
                </ng-template>
                <ng-template ngbPanelContent>
                    <div class="userListWrap">
                        <ul class="userListWrapList">
                            <!-- To be repeated for each user -->
                            <li class="userListWrapListItem" (click)="selectParticipant(participant)"
                                *ngFor="let participant of recentParticipants">
                                <div class="userListWrapListItemAvatar" [ngbPopover]="popContent"
                                    triggers="mouseenter:mouseleave" placement="right" container="body">
                                    <!-- Either image or User Initials : Hide the other one -->
                                    <img *ngIf="participant.avatarUrl && participant.avatarUrl!=''"
                                        [src]="participant.avatarUrl" alt="Avatar" style="width: 100%;">
                                    <span *ngIf="!participant.avatarUrl"
                                        class="userListWrapListItemAvatarInitals">{{getShortName(participant.firstName,
                                        participant.lastName)}}</span>
                                    <ng-template #popContent>
                                        <p class="userListWrapListItemDept userListWrapListItemInfo breakWord">
                                            <span class="userListWrapListItemInfo breakWord">{{participant.orgName}},
                                                {{participant.countryName}}</span>
                                        </p>
                                        <p class="userListWrapListItemDept userListWrapListItemInfo">
                                            <span class="userListWrapListItemInfo">{{participant.departmentName}},
                                                {{participant.jobName}} </span>
                                        </p>
                                    </ng-template>
                                </div>
                                <div class="userListWrapListItemContent">
                                    <p class="userListWrapListItemUser">{{participant.firstName}}
                                        {{participant.lastName}}</p>
                                    <p class="userListWrapListItemEmail userListWrapListItemInfo">
                                        {{participant.emailAddress}}
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </ng-template>
            </ngb-panel>
            <ngb-panel id="ngb-panel-1" *ngIf="showRATab && raParticipants.length>0">
                <ng-template ngbPanelHeader let-opened="opened">
                    <i class="accordianHeaderIcon gg-chevron-right" [ngClass]="{'collapsed': !opened}"></i>
                    <button ngbPanelToggle
                        class="btn btn-link container-fluid text-left pl-0"><span>{{'Participants.Recommended' |
                            translate}}</span></button>
                </ng-template>
                <ng-template ngbPanelContent>
                    <div class="userListWrap">
                        <ul class="userListWrapList">
                            <!-- To be repeated for each user -->
                            <li class="userListWrapListItem" (click)="selectParticipant(participant)"
                                *ngFor="let participant of raParticipants">
                                <div class="userListWrapListItemAvatar" [ngbPopover]="rapopContent"
                                    triggers="mouseenter:mouseleave" placement="right" container="body">
                                    <img *ngIf="participant.avatarUrl && participant.avatarUrl!=''"
                                        [src]="participant.avatarUrl" alt="Avatar" style="width: 100%;">
                                    <span *ngIf="!participant.avatarUrl"
                                        class="userListWrapListItemAvatarInitals">{{getShortName(participant.firstName,
                                        participant.lastName)}}</span>

                                    <ng-template #rapopContent>
                                        <p class="userListWrapListItemDept userListWrapListItemInfo breakWord">
                                            <span class="userListWrapListItemInfo breakWord">{{participant.orgName}},
                                                {{participant.countryName}}</span>
                                        </p>
                                        <p class="userListWrapListItemDept userListWrapListItemInfo">
                                            <span class="userListWrapListItemInfo">{{participant.departmentName}},
                                                {{participant.jobName}} </span>
                                        </p>
                                    </ng-template>
                                </div>
                                <div class="userListWrapListItemContent">
                                    <p class="userListWrapListItemUser">{{participant.firstName}}
                                        {{participant.lastName}}</p>
                                    <p class="userListWrapListItemEmail userListWrapListItemInfo">
                                        {{participant.emailAddress}}
                                    </p>
                                    <p class="userListWrapListItemDept userListWrapListItemInfo">
                                        <span class="userListWrapListItemInfo userListWrapListItemInfo--colorDanger">
                                            {{'Participants.NotBeenRecognized' | translate}}
                                            {{participant.lastRecognitionDays}} {{'Participants.Days' |
                                            translate}}</span>
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </ng-template>
            </ngb-panel>
            <ngb-panel id="ngb-panel-2" *ngIf="participants.length>0">
                <ng-template ngbPanelHeader let-opened="opened">
                    <i class="accordianHeaderIcon gg-chevron-right" [ngClass]="{'collapsed': !opened}"></i>
                    <button ngbPanelToggle
                        class="btn btn-link container-fluid text-left pl-0"><span>{{'Participants.Team'
                            | translate}}</span></button>
                </ng-template>
                <ng-template ngbPanelContent>
                    <div class="userListWrap">
                        <ul class="userListWrapList">
                            <!-- To be repeated for each user -->
                            <li class="userListWrapListItem" (click)="selectParticipant(participant)"
                                *ngFor="let participant of participants">
                                <div class="userListWrapListItemAvatar" [ngbPopover]="teamContent"
                                    triggers="mouseenter:mouseleave" placement="right" container="body">
                                    <!-- Either image or User Initials : Hide the other one -->
                                    <img *ngIf="participant.avatarUrl && participant.avatarUrl!=''"
                                        [src]="participant.avatarUrl" alt="Avatar" style="width: 100%;">
                                    <span *ngIf="!participant.avatarUrl"
                                        class="userListWrapListItemAvatarInitals">{{getShortName(participant.firstName,
                                        participant.lastName)}}</span>
                                    <ng-template #teamContent>
                                        <p class="userListWrapListItemDept userListWrapListItemInfo breakWord">
                                            <span class="userListWrapListItemInfo breakWord">{{participant.orgName}},
                                                {{participant.countryName}}</span>
                                        </p>
                                        <p class="userListWrapListItemDept userListWrapListItemInfo">
                                            <span class="userListWrapListItemInfo">{{participant.departmentName}},
                                                {{participant.jobName}} </span>
                                        </p>
                                    </ng-template>
                                </div>
                                <div class="userListWrapListItemContent">
                                    <p class="userListWrapListItemUser">{{participant.firstName}}
                                        {{participant.lastName}}</p>
                                    <p class="userListWrapListItemEmail userListWrapListItemInfo">
                                        {{participant.emailAddress}}
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </ng-template>
            </ngb-panel>
        </ngb-accordion>
    </div>

</div>

<div class="otlkParticipantPopupFooter">
    <button class="otlkBtn otlkBtn--primary cp w100" (click)="saveParticipant()">
        {{'Participants.DoneAdding' | translate}}
    </button>
</div>