export class ParticipantResponse {
    participants: ParticipantDetail[]
}

export class ParticipantDetail {
    id: number;
    firstName: string;
    lastName: string;
    countryName: string;
    countryCode: string;
    countryRatio: number;
    profileUrl: string;
    jobName: string;
    departmentName: string;
    orgName: string;
    organizationName: string;
    nodes: Node[];
    nodeId?: number;
    points?: number;
    emailAddress: string;
    lastRecognitionDays?: number;
    avatarUrl: string;
    type: string;
}

export class Node {
    id: number;
    name: string;
}

export class UpdateParticipantPointsCommand {
    id: number;
    points?: number;
}

export class OutlookRecipient {

    constructor(name: string, email: string) {
        this.displayName = name;
        this.emailAddress = email;
    }

    emailAddress: string;
    displayName: string;
}