import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import * as fromStore from '@app/store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  title = 'bi-addin';
  username: string;
  platform: Office.PlatformType;
  private onDestroy$ = new Subject<void>();

  constructor(private router: Router, private translate: TranslateService, private store: Store<fromStore.ProductState>) {
    if (localStorage.getItem('token') && !localStorage.getItem('public') && !localStorage.getItem('catalogue'))
      this.router.navigate([`/promotion`]);

    if (localStorage.getItem('culture'))
      translate.setDefaultLang(localStorage.getItem('culture'));
    else
      translate.setDefaultLang('en-GB');
    //translate.use('en-GB');

    this.store.select(fromStore.translationState).pipe(takeUntil(this.onDestroy$)).subscribe(state => {
      if (state.language)
        translate.setDefaultLang(state.language);
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
