export class Message {    
    constructor(_applySamePoints: boolean, _comments: string, _privateRecognition: boolean, _points?: number) {
        this.applySamePoints = _applySamePoints;
        this.comments = _comments;
        this.privateRecognition = _privateRecognition;
        this.points = _points;
    }

    applySamePoints: boolean;
    points?: number;
    comments: string;
    privateRecognition: boolean;
}

export class Toast {       
    message : string
}