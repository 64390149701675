import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PromotionService } from '@service/promotion.service';
import { PromotionResponse, EligiblePromotion, FileValidation, ShopPoints } from '@model/promotion-response.model';
import { Message } from '@model/message.model';
import { OutlookRecipient, ParticipantDetail } from '@model/participant-response.model';
import * as fromPromotionAction from '@store/actions/promotion.action';
import * as fromRecognitionAction from '@store/actions/recognition.action';
import * as fromStore from '@app/store';

import { faHourglass } from '@fortawesome/free-regular-svg-icons';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { environment } from '@env/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'promotion-page',
    templateUrl: './promotion.component.html',
    styleUrls: ['./promotion.component.scss']
})
export class PromotionComponent implements OnInit, OnDestroy {
    faHourglass = faHourglass;
    faSignOutAlt = faSignOutAlt;
    private onDestroy$ = new Subject<void>();
    public promotions: PromotionResponse;
    public selectedParticipant: ParticipantDetail[];
    public showPoints: ShopPoints;
    public loading: boolean = true;
    public isDropup: boolean = true;
    public user: string;
    public toRecipients: OutlookRecipient[] = [];
    public publicTooltip: string;

    constructor(private store: Store<fromStore.ProductState>, private router: Router, private promotionService: PromotionService,
        private ref: ChangeDetectorRef, private translateService: TranslateService) {
        this.loading = true;
        this.store.select(fromStore.recognitionState).pipe(takeUntil(this.onDestroy$)).subscribe(state => {
            this.selectedParticipant = state.participants;
        });
    }

    ngOnInit() {
        this.store.dispatch({ type: fromPromotionAction.UNSELECTED_PROMOTION });

        this.selectedParticipant.map(p => {
            this.store.dispatch({ type: fromRecognitionAction.REMOVE_PARTICIPANT, payload: p.id });
        });
        let message = new Message(true, "", false, null);
        this.store.dispatch({ type: fromRecognitionAction.UPDATE_MESSAGE, payload: message });
        this.store.dispatch({ type: fromRecognitionAction.UPDATE_BEHAVIOR, payload: null });
        this.store.dispatch({ type: fromRecognitionAction.UPDATE_ECARD, payload: null });
        this.store.dispatch({ type: fromRecognitionAction.UPDATE_FINAL_MESSAGE, payload: null });
        this.getEligiblePromotions();
        //if (!environment.production)
        this.getShopPoints();

        Office.context.mailbox.item.addHandlerAsync(Office.EventType.RecipientsChanged, (arg) => {
            this.getToRecipients();
        });

        this.getToRecipients();
    }

    getToRecipients() {
        this.toRecipients = [];
        if (Office.context.mailbox.item.to.getAsync) {
            Office.context.mailbox.item.to.getAsync((asyncResult) => {
                if (asyncResult.value) {
                    asyncResult.value.forEach(x => {
                        if (x.emailAddress.toLowerCase() != Office.context.mailbox.userProfile.emailAddress.toLowerCase()) {
                            const recipient = new OutlookRecipient(x.displayName, x.emailAddress);
                            this.toRecipients.push(recipient);
                        }
                    });
                    this.ref.detectChanges();
                }
            });
        }
        else {
            Office.context.mailbox.item.to.forEach(x => {
                if (x.emailAddress.toLowerCase() != Office.context.mailbox.userProfile.emailAddress.toLowerCase()) {
                    const recipient = new OutlookRecipient(x.displayName, x.emailAddress);
                    this.toRecipients.push(recipient);
                }
            });
            this.toRecipients.push(new OutlookRecipient(Office.context.mailbox.item.from.displayName, Office.context.mailbox.item.from.emailAddress));
        }
    }

    uniqueRecipient(outlookRecipient: OutlookRecipient[]) {
        const totalRecipient = Array.from(new Set(outlookRecipient.map(s => s.emailAddress))).map(e => {
            return new OutlookRecipient(outlookRecipient.find(s => s.emailAddress === e).displayName, e);
        });

        return totalRecipient.length - 1;
    }

    getEligiblePromotions() {
        this.promotionService.getPromotion().subscribe((response: PromotionResponse) => {
            this.promotions = response;
            this.loading = false;
            this.store.dispatch({ type: fromPromotionAction.CHANGE_RTA_STATUS, payload: response.showRATab });

            const fileValidation = new FileValidation();
            fileValidation.image = response.imageUploadSize;
            fileValidation.video = response.videoUploadSize;
            this.store.dispatch({ type: fromPromotionAction.CHANGE_FILE_UPLOAD_SIZE, payload: fileValidation });
        }, (error) => {
            this.loading = false;
        });
    }

    getShopPoints() {
        this.promotionService.getShopPoints().subscribe((response: ShopPoints) => {
            this.showPoints = response;
            this.publicTooltip = `${this.translateService.instant('Promotions.ViewPublicRecognition')} ${this.showPoints.clientName}`;
        });
    }

    recognition(promotion: EligiblePromotion) {
        this.store.dispatch({ type: fromPromotionAction.SELECTED_PROMOTION, payload: promotion });
        this.store.dispatch({ type: fromPromotionAction.ALLOW_OWN_CARD, payload: promotion.allowYourOwnCard });
        this.router.navigate(['/recognition']);
    }

    lastSent(days: number) {
        let date = new Date();
        date.setDate(date.getDate() + ((-1) * days));
        return date;
    }

    getContext() {
        if (localStorage.getItem('context'))
            return localStorage.getItem('context')
    }

    redirect() {
        /* this.promotionService.getRedirectUrlNew().subscribe(url => {
            window.open(url, '_blank', `directories=0,titlebar=0,toolbar=0,location=0,status=0,menubar=0,scrollbars=no,resizable=no,width=${screen.availWidth},height=${screen.availHeight}`);
        }); */
        localStorage.setItem('public', 'true');
        var dialogPublic;
        Office.context.ui.displayDialogAsync(`${environment.domain}/#/public`, { height: 100, width: 100, promptBeforeOpen: false }, (asyncResult) => {
            dialogPublic = asyncResult.value;
            dialogPublic.addEventHandler(Office.EventType.DialogMessageReceived, (arg) => {
                dialogPublic.close();
            });
        });
    }

    catelogRedirect(type) {
        localStorage.setItem('catalogue', type);
        var dialogCatalogue;
        Office.context.ui.displayDialogAsync(`${environment.domain}/#/catalogue`, { height: 100, width: 100, promptBeforeOpen: false }, (asyncResult) => {
            dialogCatalogue = asyncResult.value;
            dialogCatalogue.addEventHandler(Office.EventType.DialogMessageReceived, (arg) => {
                dialogCatalogue.close();
            });
        });
    }

    getTitle() {

    }

    logout() {
        localStorage.clear();
        this.router.navigate(['/']);
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}