import { Action } from '@ngrx/store';

import { ParticipantDetail, UpdateParticipantPointsCommand } from '@model/participant-response.model';
import { Message } from '@model/message.model';
import { Behaviors } from '@model/promotion-response.model';
import { ECardResponse } from '@model/ecard-response.model'
import { RecognitionResponse } from '@model/recognition-response.model';

export const ADD_PARTICIPANT = 'Add Promotion';
export const REMOVE_PARTICIPANT = 'Remove Promotion';
export const RESTORE_PARTICIPANT_TO_PREVIOUS_STATE = "Restore Participant To Previous State";
export const UPDATE_POINTS = "Update Points";

export const UPDATE_MESSAGE = 'Update Message';

export const UPDATE_BEHAVIOR = 'Update Behavior';

export const UPDATE_ECARD = 'Update Ecard';

export const UPDATE_FINAL_MESSAGE = "Update Final Message";

export const UPDATE_TOAST = "Update Toast";

export class AddParticipant implements Action {
    readonly type = ADD_PARTICIPANT;

    constructor(public payload: ParticipantDetail) { }
}

export class RemoveParticipant implements Action {
    readonly type = REMOVE_PARTICIPANT;

    constructor(public payload: number) { }
}

export class RestoreParticipantToPreviousState implements Action {
    readonly type = RESTORE_PARTICIPANT_TO_PREVIOUS_STATE;

    constructor(public payload: ParticipantDetail[]) { }
}

export class UpdatePoints implements Action {
    readonly type = UPDATE_POINTS;

    constructor(public payload: UpdateParticipantPointsCommand) { }
}

export class UpdateMessage implements Action {
    readonly type = UPDATE_MESSAGE;

    constructor(public payload: Message) { }
}

export class UpdateBehavior implements Action {
    readonly type = UPDATE_BEHAVIOR;

    constructor(public payload: Behaviors) { }
}

export class UpdateEcard implements Action {
    readonly type = UPDATE_ECARD;

    constructor(public payload: ECardResponse) { }
}

export class UpdateFinalMessage implements Action {
    readonly type = UPDATE_FINAL_MESSAGE;

    constructor(public payload: RecognitionResponse) { }
}

export class UpdateToast implements Action {
    readonly type = UPDATE_TOAST;

    constructor(public payload: RecognitionResponse) { }
}

export type PromotionAction = AddParticipant | RemoveParticipant | RestoreParticipantToPreviousState | UpdatePoints |
    UpdateMessage | UpdateBehavior | UpdateEcard | UpdateFinalMessage | UpdateToast;