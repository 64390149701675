<div class="otlkPopupHeader">
    <div class="otlkPopupHeaderInner dfr jcsb aic">
        <div>
            <button class="otlkBtn otlkBtn--tertiary cp" (click)="closeModalWindow()">
                {{'ECards.Cancel' | translate}}
            </button>
        </div>
        <div>
            <span class="fwb">{{'ECards.PhotoOrVideo' | translate}}</span>
        </div>        
    </div>
</div>

<div class="otlkPopupContent flexGrow">
    <div class="videoSectionWrap">
        <div class="videoSectionDragDropWrap">
            <div class="videoSectionDragDrop" [ngClass]="{'otlkError': fileError}" appDnd
                (fileDropped)="onFileDropped($event)">
                <div class="videoSectionGallImage" *ngIf="allowYourCard">
                    <img src="../../../assets/galThumbnail.svg" alt="Badge Avatar">
                </div>
                <div class="videoSectionDragInput" *ngIf="allowYourCard">
                    <p>{{'ECards.DragAndDrop' | translate}}</p>
                    <div class="videoSectionBrowseFiles">
                        <p class="videoSectionBrowseFilesText">{{'ECards.BrowseFiles' | translate}}</p>
                        <input class="videoSectionBrowseImages" type="file" name="" id="videoSectionBrowseImages" single
                            (change)="fileBrowseHandler($event.target.files)">
                    </div>
                </div>
                <div class="videoSectionDragUploading" *ngIf="showProgress && allowYourCard">
                    {{'ECards.Uploading' | translate}} {{progress}}%
                </div>
                <div class="videoSectionDragFormats" *ngIf="allowYourCard">
                    <p class="videoSectionDragFormatsText">JPG, PNG, GIF : {{'ECards.Maximum' | translate}} {{imageUploadSize}} {{'ECards.FileSizeExt' | translate}} </p>
                    <p class="videoSectionDragFormatsText">MP4, MOV : {{'ECards.Maximum' | translate}} {{videoUploadSize}} {{'ECards.FileSizeExt' | translate}}</p>
                </div>
                <!-- Show / hide below div based on image uploaded state -->
                <div class="videoSectionDragPreview" *ngIf="selectedECard && selectedECard.success">
                    <div class="videoSectionDragPreviewCloseWrap">
                        <div class="videoSectionDragPreviewImgWrap">
                            <img [src]="selectedECard.url" alt="">
                        </div>
                       <!--  <video style="width: 100%;" controls *ngIf="selectedECard.videoUrl!=''">
                            <source [src]="selectedECard.videoUrl" [type]="fileType">
                            Your browser does not support the video tag.
                        </video> -->
                        <div class="videoSectionDragPreviewClose" (click)="removeECard()">
                            <i class="gg-close otlkBadgeCloseIcon"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="videoSectionEcardWrap">
            <p>{{'ECards.SelectAnECard' | translate}}</p>
            <div class="videoSectionEcardImage mt10">
                <!-- Add "videoSectionEcardImageTile--selected" class to below div for selected state -->
                <div class="videoSectionEcardImageTile"
                    [ngClass]="{'videoSectionEcardImageTile--selected': selectedECard && selectedECard.id == ecard.id }"
                    *ngFor="let ecard of selectedPromotion.ecards" (click)="selectECard(ecard)">
                    <img [src]="ecard.smallImage" alt="Ecard">
                    <i class="gg-check-o videoSectionEcardImageTileCheckmark"
                        *ngIf="selectedECard && selectedECard.id == ecard.id"></i>
                </div>
            </div>
        </div>
    </div>
    <div class="otlkToast error" *ngIf="errorMessage && errorMessage!=''">
        <div class="otlkToastInner">
            <div class="otlkToastClose">
                <i class="gg-close otlkToastCloseIcon"></i>
            </div>
            <div class="otlkToastMessage">{{errorMessage}}</div>
            <div class="otlkToastCloseAction">
                <i class="gg-close otlkToastCloseActionIcon" (click)="closeToast()"></i>
            </div>
        </div>
    </div>
</div>

<div class="otlkPopupFooter">
    <button class="otlkBtn otlkBtn--primary cp w100" (click)="acceptECard()">
        {{'ECards.SaveImage' | translate}}
    </button>
</div>