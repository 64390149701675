import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import * as fromAction from '@store/actions/recognition.action';
import * as fromStore from '@app/store';
import { ECardService } from '@service/ecard.service';
import { Ecard, EligiblePromotion } from '@model/promotion-response.model';
import { ECardResponse } from '@model/ecard-response.model';

@Component({
    selector: 'ecards-modal',
    templateUrl: './ecards-modal.component.html',
    styleUrls: ['./ecards-modal.component.scss']
})
export class ECardsModalComponent implements OnInit, OnDestroy {
    private onDestroy$ = new Subject<void>();
    @Input() selectedPromotion: EligiblePromotion;
    @Output() closeModal = new EventEmitter();
    public selectedECard: ECardResponse;
    public previousECardState: ECardResponse;
    public isPreviousStateSet: boolean = false;
    public acceptableTypes: string[] = ['video/mp4', 'image/png', 'image/jpeg', 'image/gif', 'video/quicktime'];
    public fileType: string;
    public fileError: boolean = false;
    public showProgress: boolean = false;
    public progress: number = 0;
    public errorMessage: string = '';
    public imageUploadSize?: number = null;
    public videoUploadSize?: number = null;
    public allowYourCard: boolean = false;

    constructor(private ecardService: ECardService, private store: Store<fromStore.ProductState>, private translateService: TranslateService) { }

    ngOnInit() {
        this.store.select(fromStore.recognitionState).pipe(takeUntil(this.onDestroy$)).subscribe(state => {
            if (!this.isPreviousStateSet) {
                this.previousECardState = state.ecard;
                this.isPreviousStateSet = true;
            }
            this.selectedECard = state.ecard;
        });
        this.store.select(fromStore.selectedPromotionState).pipe(takeUntil(this.onDestroy$)).subscribe(state => {
            this.imageUploadSize = state.imageUploadSize;
            this.videoUploadSize = state.videoUploadSize;
            this.allowYourCard = state.allowYourOwnCard
        });
    }

    onFileDropped(files: any[]) {
        if (files.length > 0 && this.allowYourCard)
            this.uploadFile(files[0]);
    }

    fileBrowseHandler(files: any[]) {
        if (files.length > 0)
            this.uploadFile(files[0]);
    }

    uploadFile(file) {
        let fileType = String(file.type);
        this.fileError = false;
        this.errorMessage = '';
        this.showProgress = false;
        this.progress = 0;
        if (!this.acceptableTypes.includes(file.type)) {
            this.fileError = true;
            this.translateService.get('ECards.ErrorFileFormat').subscribe((res) => {
                this.errorMessage = res;
            });
        }

        if (fileType.includes('image') && Number((file.size / (1024 * 1024)).toFixed(2)) > this.imageUploadSize) {
            this.fileError = true;
            this.translateService.get('ECards.ErrorFileSize', { size: this.imageUploadSize }).subscribe((res) => {
                this.errorMessage = res;
            });
        }
        if (fileType.includes('video') && Number((file.size / (1024 * 1024)).toFixed(2)) > this.videoUploadSize) {
            this.fileError = true;
            this.translateService.get('ECards.ErrorFileSize', { size: this.videoUploadSize }).subscribe((res) => {
                this.errorMessage = res;
            });
        }
        if (this.acceptableTypes.includes(file.type) && !this.fileError) {
            this.fileType = file.type;
            this.ecardService.uploadFile(file).subscribe((response: any) => {
                if (response.status) {
                    this.showProgress = true;
                    this.progress = response.message;
                }
                else if (response.success) {
                    this.fileError = false;
                    this.showProgress = false;
                    const ecard = new ECardResponse();
                    ecard.id = null;
                    ecard.success = response.success;
                    ecard.cardName = ecard.success ? response.ownCardName : "";
                    ecard.url = ecard.success ? (response.videoUrl ? response.videoUrl : response.imageUrl) : '';
                    ecard.videoUrl = ecard.success ? (response.videoUrl ? response.imageUrl : '') : '';
                    this.store.dispatch({ type: fromAction.UPDATE_ECARD, payload: ecard });
                }
                else if (!response.success) {
                    this.fileError = true;
                }
            }, (err) => {
                console.log(err);
            });
        }
        else {
            this.fileError = true;
        }
    }

    selectECard(card: Ecard) {
        this.fileError = false;
        let ecard = new ECardResponse();
        ecard.id = card.id;
        ecard.success = true;
        ecard.url = card.smallImage;
        ecard.cardName = card.name;
        this.store.dispatch({ type: fromAction.UPDATE_ECARD, payload: ecard });
    }

    removeECard() {
        this.store.dispatch({ type: fromAction.UPDATE_ECARD, payload: null });
    }

    acceptECard() {
        this.closeModal.emit();
    }

    closeModalWindow() {
        this.store.dispatch({ type: fromAction.UPDATE_ECARD, payload: this.previousECardState });
        this.closeModal.emit();
    }

    closeToast() {
        this.errorMessage = '';
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}