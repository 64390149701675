import {
    Component,
    ElementRef,
    Input
} from '@angular/core';

import { FocusableOption } from '@angular/cdk/a11y';

@Component({
    selector: 'list-item',
    templateUrl: './list-item.component.html',
    styleUrls: ['./list-item.component.scss'],
    host: { tabindex: '-1' }
})

export class ListItemComponent implements FocusableOption {
    @Input() participant: any;
    disabled: boolean;
    isActive: boolean;

    constructor(private host: ElementRef) {
        console.log('Parti : ', this.participant);
    }

    getLabel(): any {
        return this.participant;
    }

    getShortName(firstName, lastName) {
        const fullName = firstName + ' ' + lastName;
        return fullName.split(' ').map(n => n[0]).join('');
    }

    setActive(val) {
        this.isActive = val;
    }

    focus() {
        this.host.nativeElement.focus();
    }
}