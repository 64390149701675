import * as fromRecognition from '../actions/recognition.action';
import { ParticipantDetail } from '@model/participant-response.model';
import { Behaviors } from '@model/promotion-response.model';
import { Message, Toast } from '@model/message.model';
import { ECardResponse } from '@model/ecard-response.model';
import { RecognitionResponse } from '@model/recognition-response.model';

export interface RecognitionState {
    participants: ParticipantDetail[],
    message: Message,
    behavior?: Behaviors,
    ecard?: ECardResponse,
    final?: RecognitionResponse,
    toast?: Toast
}

export const initialState: RecognitionState = {
    participants: [],
    message: new Message(true, "", false, null),
    behavior: null,
    ecard: null,
    final: null,
    toast: null
}

export function reducer(state = initialState, action: fromRecognition.PromotionAction): RecognitionState {

    switch (action.type) {
        case fromRecognition.ADD_PARTICIPANT: {
            return { ...state, participants: [...state.participants, action.payload] }
        }
        case fromRecognition.REMOVE_PARTICIPANT: {
            return { ...state, participants: state.participants.filter((item) => item.id !== action.payload) };
        }
        case fromRecognition.RESTORE_PARTICIPANT_TO_PREVIOUS_STATE: {
            return { ...state, participants: action.payload }
        }
        case fromRecognition.UPDATE_POINTS: {
            return {
                ...state, participants: state.participants
                    .map((participant, i) => participant.id === action.payload.id ? { ...participant, points: action.payload.points } : participant)
            }
        }
        case fromRecognition.UPDATE_MESSAGE: {
            return { ...state, message: action.payload };
        }
        case fromRecognition.UPDATE_BEHAVIOR: {
            return { ...state, behavior: action.payload };
        }
        case fromRecognition.UPDATE_ECARD: {
            return { ...state, ecard: action.payload };
        }
        case fromRecognition.UPDATE_FINAL_MESSAGE: {
            return { ...state, final: action.payload };
        }
        case fromRecognition.UPDATE_TOAST: {
            return { ...state, toast: action.payload };
        }
    }

    return state;
}

export const getRecognitionParticipant = (state: RecognitionState) => state.participants;