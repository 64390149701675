import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as fromAction from '@store/actions/recognition.action';
import * as fromStore from '@app/store';
import { ParticipantDetail } from '@app/models/participant-response.model';
import { Message } from '@app/models/message.model';
import { EligiblePromotion } from '@app/models/promotion-response.model';

@Component({
    selector: 'message-modal',
    templateUrl: './message-modal.component.html',
    styleUrls: ['./message-modal.component.scss']
})
export class MessageModalComponent implements OnInit, OnDestroy {
    private onDestroy$ = new Subject<void>();
    public isAllRecipCheckboxChecked: boolean = true;
    public messageLength: number = 0;
    public isPreviousStateSet: boolean = false;
    public selectedParticipant: ParticipantDetail[];
    public message: Message;
    public previousParticipantState: ParticipantDetail[];
    public previousMessageState: Message;
    public balanceError: boolean = false;
    public pointError: boolean = false;
    public messageError: boolean = false;

    @Input() selectedPromotion: EligiblePromotion;
    @Output() closeModal = new EventEmitter();

    constructor(private store: Store<fromStore.ProductState>) { }

    ngOnInit() {
        this.store.select(fromStore.recognitionState).pipe(takeUntil(this.onDestroy$)).subscribe(state => {
            this.selectedParticipant = state.participants;
            this.message = state.message;
            this.messageLength = this.message.comments.length;
            if (!this.isPreviousStateSet) {
                this.previousParticipantState = state.participants;
                this.previousMessageState = state.message;
                this.isPreviousStateSet = true;
                if (this.selectedPromotion.awardType == 'pointsFixed') {
                    let message = new Message(this.previousMessageState.applySamePoints, this.previousMessageState.comments,
                        this.previousMessageState.privateRecognition, this.selectedPromotion.awardFixed);
                    this.updateMessage(message);
                    this.applySamePoints(this.selectedPromotion.awardFixed)
                }
            }
        });
    }

    applySamePoints(points?: number) {
        let tempPoints = null;
        if (points && !isNaN(Number(points)) && points >= 0) {
            tempPoints = points;
        }
        this.selectedParticipant.map(p => {
            let command = { id: p.id, points: Number(tempPoints) };
            this.store.dispatch({ type: fromAction.UPDATE_POINTS, payload: command });
        });
        let message = new Message(this.message.applySamePoints, this.message.comments, this.message.privateRecognition, tempPoints);
        this.updateMessage(message);
    }

    updatePoints(id: number, points?: number) {
        let tempPoints = null;
        if (points && !isNaN(Number(points)) && points >= 0)
            tempPoints = points

        let command = { id: id, points: Number(tempPoints) };
        this.store.dispatch({ type: fromAction.UPDATE_POINTS, payload: command });
    }

    custom(index, item) {
        return index;
    }

    handleAllRecipiants(value) {
        if (value) {
            this.selectedParticipant.map(p => {
                let command = { id: p.id, points: Number(this.message.points) };
                this.store.dispatch({ type: fromAction.UPDATE_POINTS, payload: command });
            });
        }
        let message = new Message(value, this.message.comments, this.message.privateRecognition, this.message.points);
        this.updateMessage(message);
    }

    handlePrivateRecognition(value) {
        let message = new Message(this.message.applySamePoints, this.message.comments, value, this.message.points);
        this.updateMessage(message);
    }

    handleMessage(value) {
        let message = new Message(this.message.applySamePoints, value, this.message.privateRecognition, this.message.points);
        this.updateMessage(message);
    }

    removeParticipant(id: number) {
        this.store.dispatch({ type: fromAction.REMOVE_PARTICIPANT, payload: id });
    }

    closeModalWindow() {
        this.store.dispatch({ type: fromAction.RESTORE_PARTICIPANT_TO_PREVIOUS_STATE, payload: this.previousParticipantState });
        this.updateMessage(this.previousMessageState);
        this.closeModal.emit();
    }

    saveMessageAndAwards() {
        this.balanceError = false;
        this.pointError = false;
        this.messageError = false;
        if (this.selectedPromotion.awardAvailable) {
            this.checkValidation();
            if (!this.balanceError && !this.pointError && !this.messageError)
                this.closeModal.emit();
        }
        else {
            if (!this.message.comments || this.message.comments.length > 2000)
                this.messageError = true;
            if (!this.messageError)
                this.closeModal.emit();
        }
    }

    checkValidation() {
        let balance = this.calculateRemainingPoints(this.selectedPromotion.budgetInfo.currentValue);
        if (balance < 0) {
            this.balanceError = true;
        }

        this.selectedParticipant.map(p => {
            if (!this.pointError) {
                if (!p.points) {
                    this.pointError = true;
                }
                else {
                    if (this.selectedPromotion.awardType == 'pointsFixed') {
                        if (p.points != this.selectedPromotion.awardFixed)
                            this.pointError = true;
                    }
                    else {
                        if (p.points < this.selectedPromotion.awardMin || p.points > this.selectedPromotion.awardMax)
                            this.pointError = true;
                    }
                }
            }
        });

        if (!this.message.comments || this.message.comments.length > 2000)
            this.messageError = true;
    }

    checkAwardTypeValidation(points) {
        let returnValue = false;
        if (points) {
            returnValue = true;
        }
        else {
            if (this.selectedPromotion.awardType == 'pointsFixed') {
                if (points != this.selectedPromotion.awardFixed)
                    returnValue = true;
            }
            else {
                if (points < this.selectedPromotion.awardMin || points > this.selectedPromotion.awardMax)
                    returnValue = true;
            }
        }
        return returnValue;
    }

    updateMessage(message: Message) {
        this.store.dispatch({ type: fromAction.UPDATE_MESSAGE, payload: message });
    }

    calculateAssignedPoints() {
        if (this.selectedParticipant.length == 0)
            return 0;

        let assignedPoints = this.selectedParticipant.map(a => Math.round(a.points * a.countryRatio)).reduce(function (a, b) {
            return a + b;
        });
        if (!assignedPoints)
            return 0;

        return assignedPoints;
    }

    calculateRemainingPoints(currentValue) {
        if (this.selectedParticipant.length == 0)
            return currentValue;

        let assignedPoints = this.selectedParticipant.map(a => Math.round(a.points * a.countryRatio)).reduce(function (a, b) {
            return a + b;
        });
        return currentValue - assignedPoints;
    }

    calculateWidth(currentValue) {
        if (this.selectedParticipant.length == 0)
            return 100;

        let assignedPoints = this.selectedParticipant.map(a => Math.round(a.points * a.countryRatio)).reduce(function (a, b) {
            return a + b;
        });
        let width = ((currentValue - assignedPoints) / currentValue) * 100;
        if (width <= 0)
            width = 0;

        return width;
    }

    findMessageLength(value: string) {
        this.messageLength = value.length;
    }

    getShortName(firstName, lastName) {
        const fullName = firstName + ' ' + lastName;
        return fullName.split(' ').map(n => n[0]).join('');
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}