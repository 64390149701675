import { Component, OnInit } from '@angular/core';
import { PromotionService } from '@service/promotion.service';

@Component({
    selector: 'public-recognition',
    template: '<div>Redirecting...</div>'
})

export class PublicRecognitionComponent implements OnInit {
    constructor(private promotionService: PromotionService) { }

    ngOnInit() {
        localStorage.removeItem('public');
        this.redirect();
    }

    redirect() {
        this.promotionService.getRedirectUrl().subscribe((url: string) => {
            let auth = this.getUrlParameter(url, 'auth');
            if (!auth)
                return;

            let uri = new URL(url);
            if (document.getElementById('dynForm') != null)
                document.getElementById('dynForm').remove();

            console.log(auth);
            let form = document.createElement('form');
            form.setAttribute('id', 'dynForm');
            //form.setAttribute('target', '_blank');
            form.setAttribute('action', `${uri.origin}${uri.pathname}`);
            form.setAttribute('method', 'post');

            let input = document.createElement('input');
            input.setAttribute('type', 'hidden');
            input.setAttribute('name', 'auth');
            input.setAttribute('value', auth);

            form.appendChild(input);
            document.body.appendChild(form);
            form.submit();
            //Office.context.ui.messageParent('');
        }, error => {
            Office.context.ui.messageParent('');
        });
    }

    getUrlParameter(url, name) {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        var results = regex.exec(url);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };
}