import { Component, OnInit } from '@angular/core';
import { PromotionService } from '@service/promotion.service';
import { CatalogueResponse } from '@model/promotion-response.model';

@Component({
    selector: 'catalogue',
    template: '<div>Redirecting...</div>'
})

export class CatalogueComponent implements OnInit {
    public type: string = null;
    constructor(private promotionService: PromotionService) { }

    ngOnInit() {
        if (localStorage.getItem('catalogue')) {
            this.type = localStorage.getItem('catalogue');
            localStorage.removeItem('catalogue');
            this.redirect(this.type);
        }
    }

    redirect(redeemType: string) {
        this.promotionService.getCatalogueUrl(redeemType).subscribe((response: CatalogueResponse) => {
            
            if (!response.token || !response.url)
                return;
            
            if (document.getElementById('catalogueForm') != null)
                document.getElementById('catalogueForm').remove();
           
            let form = document.createElement('form');
            form.setAttribute('id', 'catalogueForm');
            //form.setAttribute('target', '_blank');
            form.setAttribute('action', `${response.url}`);
            form.setAttribute('method', 'post');

            let input = document.createElement('input');
            input.setAttribute('type', 'hidden');
            input.setAttribute('name', 'locator');
            input.setAttribute('value', response.token);

            form.appendChild(input);
            document.body.appendChild(form);
            form.submit();
            //Office.context.ui.messageParent('');
        }, error => {
            Office.context.ui.messageParent('');
        });
    }    
}