import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { BaseUrlService } from './url.service';


@Injectable()
export class ECardService extends BaseUrlService {
    constructor(public http: HttpClient, public router: Router) {
        super(router);
    }

    uploadFile(file: any) {
        this.checkBaseUrl();
        const body = new FormData();
        body.append('uploadAnImage', file);
        return this.http.post(`${this.instanseUrl}/mobileapp/uploadEcardMedia.do`, body, { reportProgress: true, observe: 'events' })
            .pipe(map((event) => {
                switch (event.type) {
                    case HttpEventType.UploadProgress:
                        const progress = Math.round(100 * event.loaded / event.total);
                        return { status: 'progress', message: progress };

                    case HttpEventType.Response:
                        return event.body;
                    default:
                        return `Unhandled event: ${event.type}`;
                }
                // const ecard = new ECardResponse();
                // ecard.id = null;
                // ecard.success = response.success;
                // ecard.cardName = ecard.success ? response.ownCardName : "";
                // ecard.url = ecard.success ? (response.imageUrl != '' ? response.imageUrl : response.videoUrl) : '';
                // return ecard;
            }));
    }
}