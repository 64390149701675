import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app.routing.module';
import { httpLoaderFactory } from './interceptor.registry';
import { httpInterceptorProviders } from './interceptors/token.interceptor';

import { AppComponent } from './app.component';
import { AppCodeComponent } from '@page/appcode/appcode.component';
import { LoginComponent } from '@page/login/login.component';
import { PromotionComponent } from '@page/promotion/promotion.component';
import { RecognitionComponent } from '@page/recognition/recognition.component';
import { RecognitionComponentTemp } from '@page/temp/recognition.component';
import { ParticipantModalComponent } from '@page/participant/participant-modal.component';
import { MessageModalComponent } from '@page/message/message-modal.component';
import { BehaviorModalComponent } from '@page/behavior/behavior-modal.component';
import { ECardsModalComponent } from '@page/ecards/ecards-modal.component';
import { FinalComponent } from '@page/final/final.component';
import { LoaderComponent } from '@page/loader/loader.component';
import { SsoComponent } from '@page/sso/sso.component';
import { SsoSuccessComponent } from '@app/pages/redirect/success.component';
import { SsoFailedComponent } from '@app/pages/redirect/failed.component';
import { PublicRecognitionComponent } from '@page/public-recognition/public-recognition.component';

import { BaseUrlService } from '@service/url.service';
import { AuthService } from '@service/auth.service';
import { PromotionService } from '@service/promotion.service';
import { ParticipantService } from '@service/participant.service';
import { ECardService } from '@service/ecard.service';
import { RecognitionService } from '@service/recognition.service';

import { DndDirective } from '@app/directives/dragdrop.directive';

import { reducers } from './store';
import { NgbAccordionModule, NgbPopoverModule, NgbTooltipModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { ListItemComponent } from './pages/list-item/list-item.component';

@NgModule({
  declarations: [
    AppComponent,
    AppCodeComponent,
    LoginComponent,
    PromotionComponent,
    RecognitionComponent,
    RecognitionComponentTemp,
    ParticipantModalComponent,
    MessageModalComponent,
    BehaviorModalComponent,
    ECardsModalComponent,
    FinalComponent,
    LoaderComponent,
    SsoComponent,
    SsoSuccessComponent,
    SsoFailedComponent,
    DndDirective,
    ListItemComponent,
    PublicRecognitionComponent,
    DndDirective
  ],
  imports: [
    BrowserModule,
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    StoreModule.forRoot(reducers),
    NgbPopoverModule,
    NgbAccordionModule,
    NgbTooltipModule,
    NgbDropdownModule,
    FontAwesomeModule
  ],
  providers: [
    BaseUrlService,
    AuthService,
    PromotionService,
    ParticipantService,
    ECardService,
    RecognitionService,
    ...httpInterceptorProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
