import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { AuthService } from '@service/auth.service';
import * as fromStore from '@app/store';
import * as fromTranslationAction from '@store/actions/translate.action';
import { SsoConfiguration } from '@model/login-response.model';
import { environment } from '@env/environment';

@Component({
    selector: 'appcode-page',
    templateUrl: './appcode.component.html',
    styleUrls: ['./appcode.component.scss']
})
export class AppCodeComponent implements OnInit {
    public formLogin: FormGroup;
    public isSubmitted: boolean = false;
    public errorMessage: string;
    public disabled: boolean = false;

    constructor(private router: Router, private fb: FormBuilder, private authService: AuthService, private translateService: TranslateService,
        private store: Store<fromStore.ProductState>) {
        this.formLogin = this.fb.group({
            appCode: ['', [Validators.required]]
        });
    }

    ngOnInit() {
        let displayLanguage = Office.context.displayLanguage;
        this.store.dispatch({ type: fromTranslationAction.CHANGE_LANGUAGE, payload: displayLanguage });
        if (localStorage.getItem('appcode')) {
            if (localStorage.getItem('host') && localStorage.getItem('context'))
                this.checkSso();
            else
                this.getInstanceUrl(localStorage.getItem('appcode'));
        }
    }

    validateCode() {
        this.errorMessage = '';
        this.isSubmitted = true;
        if (this.formLogin.valid) {
            this.disabled = true;
            this.getInstanceUrl(this.formLogin.value.appCode)
        }
    }

    getInstanceUrl(appcode) {
        this.authService.getInstanceUrl(appcode).subscribe((response: boolean) => {
            if (response)
                this.checkSso();
            else {
                this.disabled = false;
                this.translateService.get('Appcode.InvalidAppCode').subscribe((res)=>{
                    this.errorMessage = res;
                });                
            }
        }, error => {
            this.disabled = false;
        });
    }

    checkSso() {
        this.authService.getSsoConfiguration().subscribe((response: SsoConfiguration) => {
            if (response.ssoenabled) {
                localStorage.setItem('ssoUrl', response.ssoUrl);
                this.redirectAuthentication();
            }
            else
                this.router.navigate([`/login`]);
        }, error => {
            this.disabled = false;
        });
    }

    redirectAuthentication() {
        var dialog;
        Office.context.ui.displayDialogAsync(`${environment.domain}/#/sso`, { height: 50, width: 50, promptBeforeOpen: false }, (asyncResult) => {
            dialog = asyncResult.value;
            dialog.addEventHandler(Office.EventType.DialogMessageReceived, (arg) => {
                let response: any = JSON.parse(arg.message);
                dialog.close();
                if (response.success) {
                    localStorage.setItem('token', response.token);
                    localStorage.setItem('culture', response.culture);
                    this.store.dispatch({ type: fromTranslationAction.CHANGE_LANGUAGE, payload: response.culture });
                    this.router.navigate([`/promotion`]);
                }
                else {
                    this.disabled = false;
                    this.errorMessage = response.error;
                    localStorage.clear();
                }
            });
        });
    }

    closeToast() {
        this.errorMessage = "";
    }
}