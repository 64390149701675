<div class="otlkPopupHeader">
    <div class="otlkPopupHeaderInner dfr jcsb aic">
        <div>
            <button class="otlkBtn otlkBtn--tertiary cp" (click)="closeModalWindow()">
                {{'Message.Cancel' | translate}}
            </button>
        </div>
        <div>
            <span class="fwb">{{'Message.MessageAndAwards' | translate}}</span>
        </div>        
    </div>
</div>

<div class="otlkPopupContent flexGrow">
    <div class="awardsSectionWrap">

        <p>{{selectedPromotion.name}}</p>
        <div *ngIf="selectedPromotion.awardAvailable">
            <div class="progressSlide__slider">
                <div class="progressSlide__slider__bar"
                    [style.width.%]="calculateWidth(selectedPromotion.budgetInfo.currentValue)">
                </div>
            </div>

            <p class="recogSectionsListItemExtraInfo recogSectionsListItemExtraInfo--small mt5 colorGray9">
                <span class="colorGray9"
                    [ngClass]="{'otlkError':balanceError}">{{selectedPromotion.budgetInfo.currentValue}} -
                    {{calculateAssignedPoints()}} =
                    {{calculateRemainingPoints(selectedPromotion.budgetInfo.currentValue)}} {{'Message.PointsRemaining' | translate}}</span>
            </p>

            <p class="mt15">{{'Message.Points' | translate}}</p>
            <div class="otlkCheckbox">
                <input id="allRecip" type="checkbox" [checked]="message.applySamePoints" [disabled]="selectedPromotion.awardType == 'pointsFixed'"
                    (change)="handleAllRecipiants($event.target.checked)">
                <label for="allRecip">{{'Message.ApplySamePoints' | translate}}</label>
            </div>

            <div class="otlkInputGroup mt5" *ngIf="message.applySamePoints">
                <div class="otlkInputGroupInner otlkInputGroupInner--bordered dfr mw60"
                    [ngClass]="{'otlkError':pointError}">
                    <div class="otlkInputGroupInputHolder mw60">
                        <input class="otlkInputGroupInput noBorder" #samePoints type="text" name="pointsInput"
                            id="pointsInput" (keyup)="applySamePoints(samePoints.value)" [value]="message.points" [disabled]="selectedPromotion.awardType == 'pointsFixed'"/>
                    </div>
                    <div class="otlkInputGroupLabelHolder dfr aic jcc flexGrow">
                        <span>{{'Message.Points' | translate}}</span>
                    </div>
                </div>
                <p class="mt5 otlkInputGroupHelpText" [ngClass]="{'otlkError':pointError}"
                    *ngIf="selectedPromotion.awardType == 'pointsFixed'">
                    {{'Message.PointsShouldBe' | translate}} {{selectedPromotion.awardFixed}}
                </p>
                <p class="mt5 otlkInputGroupHelpText" [ngClass]="{'otlkError':pointError}"
                    *ngIf="selectedPromotion.awardType != 'pointsFixed'">
                    {{'Message.ValueBetween' | translate}} {{selectedPromotion.awardMin}} {{'Message.And' | translate}} {{selectedPromotion.awardMax}}
                </p>
            </div>

            <div class="selectedParticipantList" *ngIf="!message.applySamePoints">
                <div class="userListWrap">
                    <ul class="userListWrapList">
                        <!-- To be repeated for each user -->
                        <li class="userListWrapListItem userListWrapListItem--asCard"
                            *ngFor="let participant of selectedParticipant; index as i; trackBy:custom">
                            <div class="userListWrapListItemAvatar" [ngbPopover]="popContent"
                                triggers="mouseenter:mouseleave" placement="right" container="body">
                                <!-- Either image or User Initials : Hide the other one -->
                                <img *ngIf="participant.avatarUrl && participant.avatarUrl!=''"
                                    [src]="participant.avatarUrl" alt="Avatar" style="width: 100%;">
                                <span *ngIf="!participant.avatarUrl"
                                    class="userListWrapListItemAvatarInitals">{{getShortName(participant.firstName, participant.lastName)}}</span>

                                <ng-template #popContent>
                                    <p class="userListWrapListItemDept userListWrapListItemInfo">
                                        <span class="userListWrapListItemInfo">{{'Message.OrganisationName' | translate}},
                                            {{participant.organizationName}} {{participant.countryName}}</span>
                                    </p>
                                    <p class="userListWrapListItemDept userListWrapListItemInfo">
                                        <span class="userListWrapListItemInfo">{{'Message.DepartmentName' | translate}},
                                            {{participant.departmentName}}</span>
                                    </p>
                                    <p class="userListWrapListItemDept userListWrapListItemInfo">
                                        <span class="userListWrapListItemInfo">{{'Message.JobTitle' | translate}}, </span>
                                        <span class="userListWrapListItemInfo">{{participant.jobName}} </span>
                                    </p>
                                </ng-template>
                            </div>
                            <div class="userListWrapListItemContent">
                                <p class="userListWrapListItemUser">{{participant.firstName}} {{participant.lastName}}
                                </p>
                                <p class="userListWrapListItemEmail userListWrapListItemInfo">
                                    {{participant.emailAddress}}
                                </p>
                                <div class="otlkInputGroup mt5">
                                    <div class="otlkInputGroupInner otlkInputGroupInner--bordered dfr mw80"
                                        [ngClass]="{'otlkError':pointError && checkAwardTypeValidation(participant.points)}">
                                        <div class="otlkInputGroupInputHolder mw60">
                                            <input class="otlkInputGroupInput noBorder" type="text" name="pointsInput"
                                                #participantPoints
                                                (keyup)="updatePoints(participant.id,participantPoints.value)"
                                                id="pointsInput_{{i}}" [value]="participant.points">
                                        </div>
                                        <div class="otlkInputGroupLabelHolder dfr aic jcc flexGrow">
                                            <span>{{'Message.Points' | translate}}</span>
                                        </div>
                                    </div>
                                    <p class="mt5 otlkInputGroupHelpText" [ngClass]="{'otlkError':pointError}"
                                        *ngIf="selectedPromotion.awardType == 'pointsFixed'">
                                        {{'Message.PointsShouldBe' | translate}} {{selectedPromotion.awardFixed}}
                                    </p>
                                    <p class="mt5 otlkInputGroupHelpText" [ngClass]="{'otlkError':pointError}"
                                        *ngIf="selectedPromotion.awardType != 'pointsFixed'">
                                        {{'Message.ValueBetween' | translate}} {{selectedPromotion.awardMin}} {{'Message.And' | translate}}
                                        {{selectedPromotion.awardMax}}
                                    </p>
                                </div>
                            </div>
                            <div class="userListWrapListItemClose" *ngIf="selectedParticipant.length>1"
                                (click)="removeParticipant(participant.id)">
                                <i class="gg-close otlkBadgeCloseIcon"></i>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="mt15">
            <textarea name="messageText" id="messageText" class="otlkTextarea tallInput tarn" #messageText maxlength="2000"
                [ngClass]="{'otlkInputGroupInner--bordered otlkError': messageError && messageText.value.length==0}"
                [value]="message.comments" (blur)="handleMessage(messageText.value)"
                (keyup)="findMessageLength(messageText.value)" [placeholder]="'Message.EnterComments' | translate"></textarea>
            <p class="otlkTextareaHelperText">{{messageLength}} / 2,000</p>
        </div>

        <div class="otlkCheckbox">
            <input id="recogPrivate" type="checkbox" [checked]="message.privateRecognition"
                (change)="handlePrivateRecognition($event.target.checked)">
            <label for="recogPrivate">{{'Message.MakeRecognitionPrivate' | translate}}</label>
        </div>

    </div>
</div>

<div class="otlkPopupFooter">
    <button class="otlkBtn otlkBtn--primary cp w100" (click)="saveMessageAndAwards()">
        {{'Message.Continue' | translate}}
    </button>
</div>