import { Component, Input, Output, OnInit, OnDestroy, EventEmitter, ViewChild, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ParticipantService } from '@service/participant.service';
import { ParticipantDetail } from '@model/participant-response.model';
import { Toast } from '@model/message.model';
import * as fromAction from '@store/actions/recognition.action';
import * as fromStore from '@app/store';

import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { FocusKeyManager } from "@angular/cdk/a11y";
import { ListItemComponent } from "../list-item/list-item.component";
import { DOWN_ARROW, ENTER } from '@angular/cdk/keycodes';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'participant-search-modal',
    templateUrl: './participant-modal.component.html',
    styleUrls: ['participant-modal.component.scss']
})
export class ParticipantModalComponent implements OnInit, OnDestroy {
    private keyManager: FocusKeyManager<ListItemComponent>;
    @ViewChildren(ListItemComponent) listItems: QueryList<ListItemComponent>;

    faTimes = faTimes;
    private onDestroy$ = new Subject<void>();
    public isSearchFocus: boolean = false;
    public isPopupOpen: boolean = false;
    public loaded: boolean = false;
    public recentLoaded: boolean = false;
    public raLoaded: boolean = false;
    public openAutoComplete: boolean = false;
    public participants: ParticipantDetail[] = [];
    public recentParticipants: ParticipantDetail[] = [];
    public raParticipants: ParticipantDetail[] = [];
    public searchParticipants: ParticipantDetail[];
    public selectedParticipants: ParticipantDetail[];
    public previousState: ParticipantDetail[];
    public isPreviousStateSet: boolean = false;
    public showSearchError = false;
    public loading = false;
    @Input() promotionId: number;
    @Input() showRATab: boolean;
    @Output() closeModal = new EventEmitter();

    @ViewChild('searchBox') searchInput: ElementRef;

    constructor(private store: Store<fromStore.ProductState>, private participantService: ParticipantService,
        private translateService: TranslateService) { }

    ngOnInit() {
        this.store.select(fromStore.getParticipant).pipe(takeUntil(this.onDestroy$)).subscribe(state => {
            if (!this.isPreviousStateSet) {
                this.previousState = state;
                this.isPreviousStateSet = true;
            }
            this.selectedParticipants = state;
        });
        if (this.promotionId) {
            this.getRecentParticipants();
        }
    }

    ngAfterViewInit() {
        this.keyManager = new FocusKeyManager(this.listItems).withWrap();
    }

    getTeamParticipants() {
        this.participantService.getTeamMembers(this.promotionId).subscribe((response: ParticipantDetail[]) => {
            this.loaded = true;
            this.participants = response;
            this.initialRemoveSelectedParticiapnt();
        });
    }

    getRecentParticipants() {
        this.loading = true;
        this.participantService.getRecentParticipants(this.promotionId).subscribe((response: ParticipantDetail[]) => {
            this.recentLoaded = true;
            this.recentParticipants = response;
            this.loading = false;
            this.initialRemoveSelectedParticiapnt();
            this.getTeamParticipants();
            if (this.showRATab)
                this.getRA();
        }, () => {
            this.loading = false;
            this.getTeamParticipants();
            if (this.showRATab)
                this.getRA();
        });
    }

    getRA() {
        this.participantService.getRA(this.promotionId).subscribe((response: ParticipantDetail[]) => {
            this.raLoaded = true;
            this.raParticipants = response;
            this.initialRemoveSelectedParticiapnt();
        });
    }

    recipientSearch(searchText: string) {
        this.showSearchError = false;
        if (searchText.length > 2) {
            this.participantService.recipientSearch(this.promotionId, searchText).subscribe((response: ParticipantDetail[]) => {
                this.openAutoComplete = true;
                this.searchParticipants = response;
                if (this.searchParticipants.length == 0)
                    this.showSearchError = true;
                else
                    this.showSearchError = false;
            });
        }
        else {
            this.openAutoComplete = false;
            this.searchParticipants = [];
        }
    }

    selectParticipant(participant: ParticipantDetail) {
        if (this.openAutoComplete) {
            this.openAutoComplete = false;
            this.searchParticipants = [];
            this.searchInput.nativeElement.value = '';
        }
        let exist = this.selectedParticipants.find(x => x.id === participant.id);
        if (!exist) {
            this.store.dispatch({ type: fromAction.ADD_PARTICIPANT, payload: participant });

            if (participant.type == "team") {
                let index = this.participants.findIndex(x => x.id === participant.id);
                this.participants.splice(index, 1);
            }
            else if (participant.type == "ra") {
                let index = this.raParticipants.findIndex(x => x.id === participant.id);
                this.raParticipants.splice(index, 1);
            }
            else if (participant.type == "recent") {
                let index = this.recentParticipants.findIndex(x => x.id === participant.id);
                this.recentParticipants.splice(index, 1);
            }
        }
        else {
            let toast = new Toast();
            toast.message = this.translateService.instant('Participants.AlreadyExists');
            this.store.dispatch({ type: fromAction.UPDATE_TOAST, payload: toast });
            setTimeout(() => {
                this.store.dispatch({ type: fromAction.UPDATE_TOAST, payload: null });
            }, 3000);
        }

        this.keyManager.setActiveItem(-1);
        this.searchInput.nativeElement.focus();
    }

    removeParticipant(participant: ParticipantDetail) {
        this.store.dispatch({ type: fromAction.REMOVE_PARTICIPANT, payload: participant.id });

        if (participant.type == "team")
            this.participants.push(participant);
        else if (participant.type == "ra")
            this.raParticipants.push(participant);
        else if (participant.type == "recent")
            this.recentParticipants.push(participant);
    }

    initialRemoveSelectedParticiapnt() {
        this.selectedParticipants.map(participant => {
            if (participant.type == "team") {
                let index = this.participants.findIndex(x => x.id === participant.id);
                this.participants.splice(index, 1);
            }
            else if (participant.type == "ra") {
                let index = this.raParticipants.findIndex(x => x.id === participant.id);
                this.raParticipants.splice(index, 1);
            }
            else if (participant.type == "recent") {
                let index = this.recentParticipants.findIndex(x => x.id === participant.id);
                this.recentParticipants.splice(index, 1);
            }
        })

    }

    saveParticipant() {
        this.closeModal.emit();
    }

    getShortName(firstName, lastName) {
        const fullName = firstName + ' ' + lastName;
        return fullName.split(' ').map(n => n[0]).join('');
    }

    focusInput(): void {
        this.isSearchFocus = !this.isSearchFocus;
    }

    closeModalWindow() {
        this.store.dispatch({ type: fromAction.RESTORE_PARTICIPANT_TO_PREVIOUS_STATE, payload: this.previousState });
        this.closeModal.emit();
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    onKeydown(event) {
        if (event.keyCode === ENTER) {
            this.selectParticipant(this.keyManager.activeItem.participant);

        } else {
            this.keyManager.onKeydown(event);
        }
    }

    onKeyup(event) {
        if (event.keyCode === DOWN_ARROW) {
            this.keyManager.setActiveItem(0);
        }
    }
}