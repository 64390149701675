<li class="userListWrapListItem" [class.item--active]="isActive">
    <div class="userListWrapListItemAvatar" [ngbPopover]="suggestContent" triggers="mouseenter:mouseleave"
        placement="right" container="body">
        <!-- Either image or User Initials : Hide the other one -->
        <img *ngIf="participant.avatarUrl && participant.avatarUrl!=''" [src]="participant.avatarUrl" alt="Avatar"
            style="width: 100%;">
        <span *ngIf="!participant.avatarUrl"
            class="userListWrapListItemAvatarInitals">{{getShortName(participant.firstName,
            participant.lastName)}}</span>
        <!-- <span class="userListWrapListItemAvatarInitals">JL</span> -->

        <ng-template #suggestContent>
            <p class="userListWrapListItemDept userListWrapListItemInfo breakWord">
                <span class="userListWrapListItemInfo breakWord">{{participant.orgName}},
                    {{participant.countryName}}</span>
            </p>
            <p class="userListWrapListItemDept userListWrapListItemInfo">
                <span class="userListWrapListItemInfo">{{participant.departmentName}}, {{participant.jobName}} </span>
            </p>
        </ng-template>
    </div>
    <div class="userListWrapListItemContent">
        <p class="userListWrapListItemUser">{{participant.firstName}} {{participant.lastName}}
        </p>
        <p class="userListWrapListItemEmail userListWrapListItemInfo">
            {{participant.emailAddress}}</p>
    </div>
</li>